import React, { FC } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { IDropdownMenuItems } from './models';
import './DropdownMenuItems.scss';

const DropdownMenuItems: FC<IDropdownMenuItems> = ({ options }) => (
  <>
    {options.map(({ img, name }) => {
      const imgItem = img ? (
        <img className="dt-dropdown-region__img" src={`/images/${img}`} alt={name} />
      ) : null;

      return (
        <Dropdown.Item key={name} eventKey={name}>
          {imgItem}
          {name}
        </Dropdown.Item>
      );
    })}
  </>
);

export default DropdownMenuItems;
