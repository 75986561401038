import cx from 'classnames';
import React, { FC, useEffect } from 'react';

import Spinner from 'react-bootstrap/Spinner';

import { IOverlay } from './models';

import './Overlay.scss';

const Overlay: FC<IOverlay> = ({ visible }) => {
  // ---- TODO: this part needs refactoring
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '15px';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
    };
  }, [visible]);
  // ----

  return (
    <div
      className={cx('overlay', {
        visible,
      })}
    >
      <Spinner animation="grow" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Overlay;
