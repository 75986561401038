import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import cx from 'classnames';
import { Link } from 'gatsby';
import IconCustom from 'components/common/IconCustom';
import { IDropdownFilter } from './models';
import './DropdownFilter.scss';

const DropdownFilter: FC<IDropdownFilter> = ({
  setShowFilters,
  clearFilters,
  showFilters,
  filterBtnText,
  filtersCount,
}) => {
  const handleToggle = (event) => {
    event.preventDefault();
    setShowFilters(!showFilters);
  };

  const dropdownClass = cx('dt-dropdown-filter', {
    'visible-drop': showFilters,
    'selected-filter': showFilters,
  });

  return (
    <div className={dropdownClass}>
      <Button className="dt-dropdown-filter__btn-text" onClick={handleToggle} variant="secondary">
        {filterBtnText}
      </Button>
      <Link
        to="?"
        onClick={showFilters && filtersCount > 0 ? clearFilters : handleToggle}
        className="dt-dropdown-filter__btn-link"
        aria-label={filterBtnText}
      >
        <span className="accessibility-hidden">{filterBtnText}</span>
        <IconCustom icon="plus_icon" />
      </Link>
    </div>
  );
};

export default DropdownFilter;
