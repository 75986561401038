import React, { FC } from 'react';
import { Link } from 'gatsby';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import Image from 'components/common/Image';
import PlainImage from 'components/common/Image/PlainImage';

import './ProductItem.scss';

const ProductItem: FC<ProductTypes.IProduct> = (product) => {
  const classes = classNames('dt-product-item', {
    'dt-product-item--bg': product.isHovered,
  });

  return (
    <div className={classes}>
      <div className="dt-product-item__holder">
        <Link to={product.link}>
          <Card>
            <span className="dt-product-item__img">
              {product.localHeroImage && (
                <Image localImage={product.localHeroImage} alt={product.name} />
              )}
              {!product.localHeroImage && product.heroImage && (
                <PlainImage lazyLoad src={product.heroImage} alt={product.name} />
              )}
            </span>
            {product.showRating ? (
              <div data-bv-show="inline_rating" data-bv-product-id={product.ean || product.sku} />
            ) : null}
            <Card.Body>
              <Card.Title>{`${product.name} ${product.size}`}</Card.Title>
              <Card.Text>{product.recognisableFeature}</Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
