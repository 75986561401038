import React, { FC } from 'react';

import DropdownCategories from 'components/DropdownCategories';
import CategoriesNavItem from './CategoriesNavItem';
import { ICategoriesNav } from './models';
import './CategoriesNav.scss';

const CategoriesNav: FC<ICategoriesNav> = ({ selectedTag, categories, btnDropdown }) => (
  <div className="dt-categories-nav">
    <div className="d-none d-md-block">
      <nav className="dt-categories-nav__list">
        <ul>
          {categories?.map(
            (tag) =>
              tag &&
              selectedTag && (
                <CategoriesNavItem key={tag.id} tag={tag} selected={tag.id === selectedTag.id} />
              )
          )}
        </ul>
      </nav>
    </div>

    <div className="d-md-none text-center">
      <DropdownCategories btn={btnDropdown} tags={categories} />
    </div>
  </div>
);

export default CategoriesNav;
