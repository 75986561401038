import React, { FC, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useId } from 'react-id-generator';

import IconCustom from 'components/common/IconCustom';
import DropdownMenuItems from '../DropdownMenuItems';

import { IDropdownSort } from './models';

const DropdownSort: FC<IDropdownSort> = ({ options, onSelect }) => {
  const [dropId] = useId();
  const [name, setName] = useState<string>(options[0].name);
  const handleSelect = (filter) => {
    setName(filter);
    onSelect(filter);
  };

  return (
    <Dropdown
      className="dt-dropdown-sort"
      id={`dt-dropdown-sort-${dropId}`}
      onSelect={handleSelect}
    >
      <Dropdown.Toggle variant="secondary">
        <span>{name}</span>
        <IconCustom icon="next_arrow" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <DropdownMenuItems options={options} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSort;
