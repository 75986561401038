import React, { FC } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import { ICategoriesNavItemProps } from './models';

const CategoriesNavItem: FC<ICategoriesNavItemProps> = ({ selected, tag: { name, link, title } }) =>
  link ? (
    <li>
      <Link to={extractUrlFromMultiPicker(link)} className={cx({ selected })}>
        {title || name}
      </Link>
    </li>
  ) : null;

export default CategoriesNavItem;
