import React, { FC, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import DropdownSort from 'components/DropdownSort';
import DropdownFilter from 'components/DropdownFilter';
import DropdownFilterItem from 'components/DropdownFilter/DropdownFilterItem';

import { IProductListFilter } from './models';
import './ProductListFilter.scss';

const ProductListFilter: FC<IProductListFilter> = ({
  shownCount,
  setShowFilters,
  totalCount,
  hideSortButton,
  filtersCount,
  onSelect,
  options,
  fetchProduct,
  clearFilters,
  showFilters,
  filterBtnText,
  filterTypes,
  applyFiltersText,
  showingOf,
}) => {
  const showing =
    showingOf &&
    showingOf
      .replace(
        '%num%',
        `<strong className="dt-product-list-filter__label-bold">${shownCount}</strong>`
      )
      .replace(
        '%num%',
        `<strong className="dt-product-list-filter__label-bold">${totalCount}</strong>`
      );

  const dropdownFilterItems = useMemo(
    () =>
      filterTypes?.map((item) => (
        <>
          <DropdownFilterItem key={item.id} tag={item} fetchProduct={fetchProduct} />
        </>
      )),

    [filterTypes, fetchProduct]
  );

  return (
    <>
      {filterTypes && (
        <div className="dt-product-list-filter">
          <div className="dt-product-list-filter__holder">
            <DropdownFilter
              filtersCount={filtersCount}
              setShowFilters={setShowFilters}
              showFilters={showFilters}
              clearFilters={clearFilters}
              filterBtnText={filterBtnText}
            />

            <div className="dt-product-list-filter__col">
              <span
                className="dt-product-list-filter__label"
                dangerouslySetInnerHTML={{ __html: showing }}
              />

              {Number(hideSortButton) ? null : (
                <DropdownSort options={options} onSelect={onSelect} />
              )}
            </div>
          </div>
          {showFilters ? (
            <div className="dt-filter-product-drop">
              {dropdownFilterItems}
              <Button className="d-md-none" variant="primary" onClick={() => setShowFilters(false)}>
                {applyFiltersText}
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default ProductListFilter;
