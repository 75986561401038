// eslint-disable-next-line import/prefer-default-export
export const getSelectedFilters = (
  tag: PageContent.TTagWithImageAndSubTitle,
  currentFilters: Array<string | number>
) => {
  const { id } = tag;
  let selectedFilters = [...currentFilters];
  const foundIndex = selectedFilters.indexOf(Number(id));
  if (foundIndex === -1) {
    selectedFilters.push(id);
  } else {
    selectedFilters = [
      ...selectedFilters.slice(0, foundIndex),
      ...selectedFilters.slice(foundIndex + 1),
    ];
  }

  return { foundIndex, selectedFilters };
};
