import React, { FC } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import { getLocationQueryStringParam } from 'utils/browser';
import { getSelectedFilters } from './utils';

import './DropdownFilterItem.scss';

type TDropdownFilterItemComponent = {
  tag: PageContent.TTagWithImageAndSubTitle;
  fetchProduct: (currentFilters?: never[]) => Promise<void>;
};
const DropdownFilterItem: FC<TDropdownFilterItemComponent> = ({ tag, fetchProduct }) => {
  const { image, imageAlt, name, id, title, subTitle } = tag;
  const { foundIndex, selectedFilters } = getSelectedFilters(
    tag,
    getLocationQueryStringParam('type')
  );

  const handleItemClick = () => {
    fetchProduct([selectedFilters]);
  };

  return (
    <Link
      to={`?type=${selectedFilters.join(',')}`}
      data-value={id}
      onClick={handleItemClick}
      className={cx('dt-dropdown-filter-item', {
        selected: foundIndex !== -1,
      })}
    >
      <span className="dt-dropdown-filter-item__img-holder">
        <UmbracoImage image={image} alt={imageAlt} />
      </span>

      <span className="dt-dropdown-filter-item__holder">
        <strong className="dt-dropdown-filter-item__title">{title || name}</strong>
        <span className="dt-dropdown-filter-item__info">{subTitle}</span>
      </span>
    </Link>
  );
};

export default DropdownFilterItem;
